import ExlyTableEmptyState from "common/Components/ExlyTable/ExlyTableEmptyState/ExlyTableEmptyState";
import React from "react";
import FormQuestionSelector from "../FormQuestionSelector/FormQuestionSelector";
import classes from "./MobileView.module.css";
import { generateRandomString } from "utils/Utils";
import AnswerCard from "./modules/QuestionResponses/AnswerCard";
import ProgressTrackingFormNote from "./modules/ProgressTrackingFormNote/ProgressTrackingFormNote";
import { useMobileFormResponsesController } from "./utils/useMobileFormResponsesController";
import { Pagination } from "common/Components/Pagination";
import ExlySpinner from "ui/modules/ExlySpinner/ExlySpinner";
import LoadingItem from "ui/pages/communications/Certificates/components/TabSection/components/CertificatesTable/components/CertificatesMobileTable/LoadingItem";
import { DEFAULT_PER_PAGE } from "constants/numbers";
import { getDeviceSpecificNoFormSelectedEmptyStateProps } from "../../utils/FormsV2.utils";
import { FORM_NOT_ATTEMPTED_YET_EMPTY_PROPS } from "../../constants/FormsV2.constants";

import classnames from "classnames";
import ExlyEmptyState from "features/Common/modules/ExlyEmptyState/ExlyEmptyState";

const SHIMMER_ITEMS_SIZE = 10;

const shimmerArray = [...Array(SHIMMER_ITEMS_SIZE).keys()];

const MobileView = ({ emptyStateProps, bookingUuid, listingUuid }) => {
  const {
    formOptions,
    isFormProgressTracking,
    isEmptyState,
    selectedForm,
    setSelectedForm,
    selectedQuestion,
    setSelectedQuestion,
    responses,
    formLastElementRef,
    formLoading,
    loading,
    page,
    onPageChange,
    total,
    currentCourseUsageCount,
    otherCoursesUsageCount,
    isSingleFormNotAttemptedYet,
  } = useMobileFormResponsesController({
    bookingUuid,
    listingUuid,
  });

  const addPaddingToContainer =
    isFormProgressTracking && total > DEFAULT_PER_PAGE;

  if (!formLoading && isEmptyState)
    return <ExlyTableEmptyState {...emptyStateProps} />;
  if (formLoading)
    return (
      <div className={classes.loader}>
        <ExlySpinner loaderWidth="64px" wrapperPadding="0 0" />
      </div>
    );

  {
    /**
     * 1. If Loading show a shimmer array of items.
     * 2. If not loading but form was not attempted show empty state.
     * 3. If not loading and respones are there then display responses.
     */
  }

  const Responses = isSingleFormNotAttemptedYet ? (
    <div className={classes.emptyFormState}>
      <ExlyEmptyState {...FORM_NOT_ATTEMPTED_YET_EMPTY_PROPS} />
    </div>
  ) : (
    responses &&
    responses.map((response) => (
      <AnswerCard
        key={response?.uuid || generateRandomString()}
        response={response}
        isFormProgressTracking={isFormProgressTracking}
      />
    ))
  );
  const Table = loading
    ? shimmerArray.map((index) => <LoadingItem key={index} />)
    : Responses;

  return (
    <div className={classes.table}>
      <div
        className={classnames(
          classes.container,
          addPaddingToContainer && classes.paddedContainer
        )}
      >
        <div className={classes.body}>
          <FormQuestionSelector
            lastElementRef={formLastElementRef}
            formOptions={formOptions}
            loading={formLoading}
            enableQuestionSelector={isFormProgressTracking}
            selectedForm={selectedForm}
            selectedQuestion={selectedQuestion}
            setSelectedForm={setSelectedForm}
            setSelectedQuestion={setSelectedQuestion}
            bookingUuid={bookingUuid}
          />

          {/** In case form is not selected */}
          {!responses && !loading && (
            <div className={classes.emptyFormState}>
              <ExlyEmptyState
                {...getDeviceSpecificNoFormSelectedEmptyStateProps(false)}
              />
            </div>
          )}
          {Table}
        </div>

        {/** In case form was progress tracking show are note. */}
        {isFormProgressTracking && (
          <ProgressTrackingFormNote
            currentCourseUsageCount={currentCourseUsageCount}
            otherCoursesUsageCount={otherCoursesUsageCount}
          />
        )}
      </div>
      <Pagination
        page={page}
        perPage={DEFAULT_PER_PAGE}
        onPageChange={onPageChange}
        total={total}
        mobilePaginationProps={{
          wrapperClassName: classes.paginationWrapper,
        }}
      />
    </div>
  );
};

export default MobileView;
