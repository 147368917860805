import { DEFAULT_PER_PAGE } from "constants/numbers";
import { dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { useInfiniteFormOptions } from "features/RecordedContent/modules/FormsV2/utils/useInfiniteFormOptions";
import { useEffect, useState } from "react";
import { is_empty } from "utils/validations";
import { FORM_RESPONSES_FIELDS } from "../../DesktopForms/utils/DesktopView.utils";
import { logError } from "utils/error";
import { QUESTIONNAIRE_FORM_KEYS } from "schedule-v2/components/recorded-content/recordedContent.data";
import { FORMSV2_API } from "features/RecordedContent/modules/FormsV2/utils/FormsV2.utils";
import { checkIfIsFormProgressTracking } from "../../../utils/FormsV2.utils";

export const useMobileFormResponsesController = (props) => {
  const {
    options: formOptions,
    loading: formLoading,
    lastElementRef: formLastElementRef,
  } = useInfiniteFormOptions(props);
  const [selectedForm, setSelectedForm] = useState();
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [responses, setResponses] = useState();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState();

  const isFormProgressTracking = checkIfIsFormProgressTracking(
    selectedForm?.[FORM_RESPONSES_FIELDS.RECORDED_CONTENT_TYPE]
  );

  const isEmptyState = is_empty(formOptions) && !formLoading;
  const currentCourseUsageCount =
    selectedForm &&
    selectedForm[QUESTIONNAIRE_FORM_KEYS.currentCourseUsageCount];
  const otherCoursesUsageCount =
    selectedForm &&
    selectedForm[QUESTIONNAIRE_FORM_KEYS.otherCoursesUsageCount];
  const isSingleFormNotAttemptedYet =
    responses && is_empty(responses) && !isFormProgressTracking;

  const onPageChange = (pageNumber) => setPage(pageNumber);

  useEffect(() => {
    if (selectedForm) {
      const defaultQueryParams = {
        page: page,
        page_size: DEFAULT_PER_PAGE,
        booking_uuid: props.bookingUuid,
        content_uuid: selectedForm[FORM_RESPONSES_FIELDS.RECORDED_CONTENT_UUID],
      };

      /**
       * In case of switching from single to progress tracking and
       * vice-versa will need to set the respones to null
       */
      setResponses();

      /**
       * form is progress tracking and question is not selected dont make the call to fetch.
       */
      if (isFormProgressTracking && !selectedQuestion) return;

      const apiUrl = isFormProgressTracking
        ? FORMSV2_API.GET_PAGINATED_PROGRESS_TRACKING_FORM_RESPONSES_MOBILE
        : FORMSV2_API.GET_PAGINATED_FORM_RESPONSES;

      const queryParams = isFormProgressTracking
        ? {
            ...defaultQueryParams,
            question_uuid: selectedQuestion.question_uid,
          }
        : defaultQueryParams;

      setLoading(true);
      dataProvider
        .custom_request(apiUrl, apiMethods.GET, queryParams)
        .then(({ data: { responses, questions, total } }) => {
          setResponses(isFormProgressTracking ? responses : questions);
          setTotal(total);
        })
        .catch((error) =>
          logError({
            error,
            occuredAt:
              "src/features/CourseAnalytics/modules/ContentResponses/FormsV2/components/Mobile/utils/useMobileFormResponsesController.js",
            when: "Fetching responses for progress tracking form",
          })
        )
        .finally(() => setLoading(false));
    }
  }, [selectedForm, selectedQuestion, page]);

  return {
    formOptions,
    formLastElementRef,
    formLoading,
    isFormProgressTracking,
    isEmptyState,
    selectedForm,
    setSelectedForm,
    selectedQuestion,
    setSelectedQuestion,
    responses,
    loading,
    page,
    onPageChange,
    total,
    currentCourseUsageCount,
    otherCoursesUsageCount,
    isSingleFormNotAttemptedYet,
  };
};
