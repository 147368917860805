import { Divider } from "@material-ui/core";
import { Select } from "@my-scoot/component-library-legacy";

import React from "react";
import style from "./FormQuestionSelector.module.css";
import classnames from "classnames";
import { useInfiniteFormQuestions } from "../../utils/useInfiniteFormQuestions";
import { FORM_RESPONSES_FIELDS } from "../DesktopForms/utils/DesktopView.utils";

const QuestionSelector = ({
  selectedForm,
  selectedQuestion,
  setSelectedQuestion,
  bookingUuid,
}) => {
  const {
    options: questionOptions,
    loading,
    lastElementRef,
  } = useInfiniteFormQuestions({
    contentUuid: selectedForm[FORM_RESPONSES_FIELDS.RECORDED_CONTENT_UUID],
    bookingUuid: bookingUuid,
    setSelectedQuestion,
  });

  return (
    <Select
      fullWidth
      label={
        <div className={style.info}>
          Select a question to see its responses
          <span className={style.star}>*</span>
        </div>
      }
      loading={loading}
      size="small"
      mobileModalTitle="Select question"
      value={selectedQuestion}
      options={questionOptions}
      renderSelectedValue={({ selectedValue }) =>
        selectedValue ? selectedValue?.question : "Select question"
      }
      onChange={setSelectedQuestion}
      renderOption={({ option, onChange, isLast }) => {
        const { question, question_uid } = option;
        return (
          <div
            onClick={() => onChange(option)}
            {...(isLast ? { ref: lastElementRef } : {})}
            className={classnames(
              style.option,
              selectedQuestion?.question_uid === question_uid &&
                style.selectedOption
            )}
          >
            <span>{question}</span>
          </div>
        );
      }}
    />
  );
};

const FormQuestionSelector = ({
  lastElementRef,
  loading,
  formOptions,
  selectedForm,
  setSelectedForm,
  selectedQuestion,
  enableQuestionSelector,
  setSelectedQuestion,
  bookingUuid,
}) => {
  return (
    <>
      <Select
        fullWidth
        label={
          <div className={style.info}>
            Select a form to see its responses
            <span className={style.star}>*</span>
          </div>
        }
        loading={loading}
        size="small"
        mobileModalTitle="Select form"
        value={selectedForm}
        options={formOptions}
        renderSelectedValue={({ selectedValue }) =>
          selectedValue ? selectedValue?.title : "Select form"
        }
        onChange={(e) => {
          if (e != selectedForm) {
            setSelectedForm(e);
            // In case of changing one progress form to another we will need to reset selected question
            if (selectedQuestion && setSelectedQuestion) setSelectedQuestion();
          }
        }}
        renderOption={({ option, onChange, isLast }) => {
          const { title, uuid } = option;
          return (
            <div
              onClick={() => onChange(option)}
              {...(isLast ? { ref: lastElementRef } : {})}
              className={classnames(
                style.option,
                selectedForm?.uuid === uuid && style.selectedOption
              )}
            >
              <span>{title}</span>
            </div>
          );
        }}
      />

      {enableQuestionSelector && selectedForm && (
        <>
          <Divider />
          <QuestionSelector
            selectedQuestion={selectedQuestion}
            setSelectedQuestion={setSelectedQuestion}
            selectedForm={selectedForm}
            bookingUuid={bookingUuid}
          />
        </>
      )}
    </>
  );
};

export default FormQuestionSelector;
