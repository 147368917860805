import { isInternationalTimezone } from "utils/AuthUtil";

// if: lines of code > 300
// then: breakdown this file and move to src/features/Common/utils folder
export const isPositiveNumber = (input) => {
  // tests for positive integers only
  const reg = new RegExp(/^\d*$/);
  return reg.test(input);
};

/**
 * Generates factors of the given number L, excluding L itself if non-prime.
 * @param {number} L - The number for which factors need to be generated.
 * @returns {Array} An array of factors of L (excluding L itself if non-prime).
 */
export const generateFactors = (L) => {
  if (L === 1) {
    return [1];
  }
  const factors = [];
  for (let i = 1; i <= Math.floor(Math.sqrt(L)); i++) {
    if (L % i === 0) {
      factors.push(i);
      if (i !== L / i) {
        factors.push(L / i);
      }
    }
  }

  // Exclude L itself if it's not prime
  const filteredFactors = factors.filter((factor) => factor !== L);
  return filteredFactors.sort((a, b) => a - b);
};

export const roundOff = (value, places) => {
  if (value % 1 == 0) return value / 1;
  return (Math.round(value * 100) / 100).toFixed(places);
};

/**
 * @dev
 * Formats a number into a human-readable string using Indian numbering system units.
 * It abbreviates large numbers into Crores (Cr), Lakhs (L), and Thousands (k).
 * The function determines the appropriate unit based on predefined thresholds.
 * @param {number} number - The number to format.
 * @param {boolean} isInternational - Flag indicating whether to apply international time zone formatting.
 * @returns {string} - The formatted number with the appropriate unit.
 */
export function formatNumber(number) {
  const thresholds = [
    { threshold: 1e7, unit: "Cr" },
    { threshold: 1e5, unit: "L" },
    { threshold: 1e3, unit: "k" },
  ];

  for (const { threshold, unit } of thresholds) {
    if (number >= threshold) {
      const formattedNumber = (number / threshold).toFixed(2) + unit;
      return isInternationalTimezone()
        ? formattedNumber + "k"
        : formattedNumber;
    }
  }

  const defaultFormattedNumber = number.toString();
  return isInternationalTimezone()
    ? defaultFormattedNumber + "k"
    : defaultFormattedNumber;
}

export const roundOffIfDecimal = (val, places = 2) => {
  if (Number.isInteger(val)) return parseInt(val);
  return roundOff(val, places);
};

export const getFibonacciNthValue = (n) => {
  let a = 0;
  let b = 1;
  for (let i = 2; i <= n; i++) {
    [a, b] = [b, a + b];
  }
  return n ? b : a;
};
