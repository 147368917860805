import React from "react";
import style from "./QuestionResponses.module.css";
import { FORM_NOT_ATTEMPTED_YET_LABEL } from "./constants/QuestionResponses.constants";
import { FormQuestion } from "schedule-v2/components/recorded-content/ExamCreation/pages/Questionnaire/HelperComponents/components/HelperComponents";
import {
  QUESTION_KEYS,
  QUESTION_TYPES,
} from "schedule-v2/components/recorded-content/recordedContent.data";

const ProgressTrackingFormAnswerCard = (response) => {
  const {
    title,
    is_attempted = false,
    [QUESTION_KEYS.questionType]: type,
  } = response;
  return (
    <div className={style.card}>
      <div className={style.title}>
        <img
          src={"assets/vectors/recorded_content/location.svg"}
          alt="location"
        />
        {title}
      </div>
      <div className={style.body}>
        {is_attempted ? (
          <div
            {...(type == QUESTION_TYPES.TEXT_QUESTION
              ? { className: style.textBody }
              : {})}
          >
            <FormQuestion question={response} isDisabled isResponse />
          </div>
        ) : (
          FORM_NOT_ATTEMPTED_YET_LABEL
        )}
      </div>
    </div>
  );
};

const SingleFormAnswerCard = (response) => {
  const {
    question,
    isFormNotAttempedYet,
    [QUESTION_KEYS.questionType]: type,
  } = response;
  return (
    <div className={style.singleformbody}>
      <div className={style.dashedDivider} />
      <div className={style.singleFormAnswer}>
        <div className={style.singleFormAnswerTitle}>{question}</div>
        {isFormNotAttempedYet ? (
          FORM_NOT_ATTEMPTED_YET_LABEL
        ) : (
          <div
            {...(type == QUESTION_TYPES.TEXT_QUESTION
              ? { className: style.textBody }
              : {})}
          >
            <FormQuestion question={response} isDisabled isResponse />
          </div>
        )}
      </div>
    </div>
  );
};

const AnswerCard = ({ response, isFormProgressTracking }) => {
  if (isFormProgressTracking)
    return <ProgressTrackingFormAnswerCard {...response} />;
  return <SingleFormAnswerCard {...response} />;
};

export default AnswerCard;
