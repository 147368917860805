export const video_format_keys = {
  // video
  avi: "avi",
  mp4: "mp4",
  mpeg: "mpeg",
  ogv: "ogv",
  ts: "ts",
  webm: "webm",
  mov: "mov",
  "3gp": "3gp",
  "3g2": "3g2",
};

export const audio_format_keys = {
  // audio
  aac: "aac",
  m4a: "m4a",
  mp3: "mp3",
  wav: "wav",
  wma: "wma",
};

export const compressed_format_keys = {
  // compressed files
  zip: "zip",
};

export const docs_format_keys = {
  // docs files
  csv: "csv",
  doc: "doc",
  docx: "docx",
  html: "html",
  json: "json",
  pdf: "pdf",
  ppt: "ppt",
  pptx: "pptx",
  xlsx: "xlsx",
};

export const images_format_keys = {
  // images
  avif: "avif",
  heic: "heic",
  heif: "heif",
  jpeg: "jpeg",
  jpg: "jpg",
  png: "png",
  webp: "webp",
  gif: "gif",
  svg: "svg",
  tiff: "tiff",
};

/**
 * alphabetically sorted
 */
export const file_format_keys = {
  ...audio_format_keys,
  ...compressed_format_keys,
  ...docs_format_keys,
  ...images_format_keys,
  ...video_format_keys,
};

export const HTML_MIME_TYPE = "text/html";
export const JSON_MIME_TYPE = "application/json";
export const PDF_MIME_TYPE = "application/pdf";

/**
 * alphabetically sorted
 *
 * reference: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 */
export const file_mime_types = {
  // audio
  [file_format_keys.aac]: ["audio/aac"],
  [file_format_keys.m4a]: ["audio/x-m4a"],
  [file_format_keys.mp3]: ["audio/mpeg"],
  [file_format_keys.wav]: ["audio/wav"],
  [file_format_keys.wma]: ["audio/x-ms-wma", "video/x-ms-wma"],

  // compressed files
  [file_format_keys.zip]: ["application/zip"],

  // docs
  [file_format_keys.csv]: ["text/csv"],
  [file_format_keys.doc]: ["application/msword"],
  [file_format_keys.docx]: [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ],
  [file_format_keys.html]: [HTML_MIME_TYPE],
  [file_format_keys.json]: [JSON_MIME_TYPE],
  [file_format_keys.pdf]: [PDF_MIME_TYPE],
  [file_format_keys.ppt]: ["application/vnd.ms-powerpoint"],
  [file_format_keys.pptx]: [
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ],
  [file_format_keys.xlsx]: [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ],

  // images
  [file_format_keys.avif]: ["image/avif"],
  [file_format_keys.heic]: ["image/heic"],
  [file_format_keys.heif]: ["image/heif"],
  [file_format_keys.jpeg]: ["image/jpeg"],
  [file_format_keys.jpg]: ["image/jpeg"],
  [file_format_keys.png]: ["image/png"],
  [file_format_keys.webp]: ["image/webp"],
  [file_format_keys.gif]: ["image/gif"],
  [file_format_keys.svg]: ["image/svg+xml"],
  [file_format_keys.tiff]: ["image/tiff"],

  // videos
  [file_format_keys.avi]: ["video/x-msvideo", "video/avi"],
  [file_format_keys.mp4]: ["video/mp4"],
  [file_format_keys.mpeg]: ["video/mpeg"],
  [file_format_keys.ogv]: ["video/ogg"],
  [file_format_keys.ts]: ["video/mp2t"],
  [file_format_keys.webm]: ["video/webm"],
  [file_format_keys["3gp"]]: ["video/3gpp", "audio/3gpp"],
  [file_format_keys["3g2"]]: ["video/3gpp2", "audio/3gpp2"],
  [file_format_keys.mov]: ["video/quicktime"],
};

export const [TS_VIDEO_MIME_TYPE] = file_mime_types[file_format_keys.ts];

export const FILE_SIZES = {
  MAX_IMAGE_SIZE: {
    value: 5242880,
    label: "5MB",
  },
  MAX_DOC_SIZE: {
    value: 104857600,
    label: "100MB",
  },
  MAX_VIDEO_SIZE: {
    value: 16777216,
    label: "16MB",
  },
};

export const MIME_TYPE_TO_EXTENSION_MAP = Object.entries(
  file_mime_types
).reduce((prev, [extension, mimeTypes]) => {
  mimeTypes.forEach((type) => (prev[type] = extension));
  return prev;
}, {});
