import React from "react";
import style from "./ProgressTrackingFormNote.module.css";
const ProgressTrackingFormNote = ({
  currentCourseUsageCount = 0,
  otherCoursesUsageCount = 0,
}) => {
  return (
    <div className={style.container}>
      <div className={style.body}>
        <img
          src={
            "assets/vectors/recorded_content/progress_tracking_form_blue.svg"
          }
        />
        <div>
          <strong>Progress Tracking Form:</strong> It has been used{" "}
          <strong>{currentCourseUsageCount}</strong> times in this course and{" "}
          <strong>{otherCoursesUsageCount}</strong> times across other courses.
        </div>
      </div>
    </div>
  );
};

export default ProgressTrackingFormNote;
