import React, { useEffect } from "react";
import { DesktopTable } from "@my-scoot/component-library-legacy";
import classnames from "classnames";
import classes from "./ExlyTable.module.css";

/**
 * DEV - New ExlyTableV2 which can be used by sending rows columns loading etc.
 * props without using reactAdminProps.
 * This will build as you need process.
 * Currently is desktop supported.
 *
 * Developers and reviewers need to ensure that any new updates
 * in table component are updated in both V2 and V1 tables
 */
const ExlyTable = (props) => {
  const {
    paginationProps,
    columns,
    rows,
    loading,
    stickyHeader,
    tableContainerClassName = "",
  } = props;

  /**
   * DEV - Needed to set width of main-content as 0.
   * Since table was overflowing horizontally from div.
   * Similar has been done in ExlyTable using List and
   * adding a content class which has width 0 property.
   */
  useEffect(() => {
    document
      .querySelector("#main-content")
      .classList.add(classes.listContentRoot);

    return () =>
      document
        .querySelector("#main-content")
        .classList.remove(classes.listContentRoot);
  }, []);

  return (
    <div className={classes.exlyTableRoot}>
      <DesktopTable
        columns={columns}
        rows={rows}
        loading={loading}
        stickyHeader={stickyHeader}
        tableContainerClassName={classnames(
          classes.tableWrapper,
          tableContainerClassName
        )}
        {...(paginationProps.total > paginationProps.perPage
          ? {
              paginationProps: {
                ...paginationProps,
              },
            }
          : {})}
      />
    </div>
  );
};

export default ExlyTable;
