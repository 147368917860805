import { api, dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { LOCAL_STORAGE_KEYS } from "features/Common/modules/Storage/modules/LocalStorage/constants/LocalStorage.constants";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "features/Common/modules/Storage/modules/LocalStorage/utils/LocalStorage.utils";
import { logError } from "utils/error";
import { isRequestSuccessful } from "utils/Utils";

//@Dev, ensure to call this function in try catch block.
export const updateFeatureOnboardingFlagV2 = async ({
  featureKey,
  value = false,
}) => {
  if (!featureKey) throw new Error("Feature key is required");

  try {
    const allFeaturesData = JSON.parse(
      getLocalStorageItem(LOCAL_STORAGE_KEYS.IS_FEATURE_ONBOARDED)
    );

    const { status } = await dataProvider.custom_request(
      api.onboarding_flag.update,
      apiMethods.POST,
      {
        flag: featureKey,
        value,
      }
    );
    if (!isRequestSuccessful(status)) {
      throw new Error("Error while updating feature onboarding flag");
    }
    const updatedData = {
      ...(allFeaturesData || {}),
      [featureKey]: value,
    };
    setLocalStorageItem(
      LOCAL_STORAGE_KEYS.IS_FEATURE_ONBOARDED,
      JSON.stringify(updatedData)
    );
    return updatedData;
  } catch (error) {
    logError({
      error,
      when: "An error occurred while updating the feature onboarding flag:",
      occuredAt:
        "updatefeatureOnboardingFlagV2 in src/features/Common/FeatureOnboardingV2/utils/FeatureOnboardingV2.utils.js",
    });
    throw error; // Re-throw the error for upstream handling
  }
};
