import { isInternationalTimezone } from "utils/AuthUtil";
import { is_empty, isValidEmail, isValidMobile } from "utils/validations";
import { MAX_2_DECIMAL_NUMBER_REGEX } from "../constants/Form.regex";

export const validatePercentage = (value) => {
  const numberValue = parseFloat(value);

  if (isNaN(numberValue)) return "Invalid Number";
  if (numberValue <= 0 || numberValue > 100)
    return "Value must be between 0 and 100";
  if (!MAX_2_DECIMAL_NUMBER_REGEX.test(value))
    return "Value must have at most 2 decimal places";

  return undefined;
};

export const validatePrice = (price, allValues) => {
  const { selected_quantity } = allValues;
  if (selected_quantity * price < 1) return "Invalid";
};

export const required = (value) => {
  if (is_empty(value)) return "Required";
};

export const validateEmail = (email) => {
  if (!isValidEmail(email)) return "Invalid Email";
};

export const validatePhoneNumber = (phoneNumber, allValues) => {
  const { country_code } = allValues;
  const _phone = phoneNumber
    .replaceAll(" ", "")
    .replaceAll("-", "")
    .slice(country_code.length);
  if (!isInternationalTimezone() && !isValidMobile(country_code, _phone))
    return "Invalid Phone Number";
};

export const validateNaturalNumber = (n) => {
  if (!(Number.isInteger(n) && n > 0)) return "Invalid value";
};

export const checkIsAlphaNumeric = (value) => value.match(/^[0-9a-zA-Z]+$/);

/**
 * @deprecated use validateNameLengthOnly instead to allow special characters as well
 */
// This checks if the name is alpha numeric, allows spaces and underscore
export const validateNameField = (value) => value.match(/^[0-9a-zA-Z _]+$/);

export const validateMaxLength = (value, length) => {
  if (`${value.length}` > length) return `Max ${length} characters`;
};

// allow all characters but disallow strings of only spaces, and minimum length of 3
export const validateNameLengthOnly = (value) => value.match(/^(?!\s*$).{3,}$/);
