import React from "react";
import useInfiniteSearch from "hooks/useInfiniteSearch";
import { dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { FORMSV2_API } from "features/RecordedContent/modules/FormsV2/utils/FormsV2.utils";
import { DEFAULT_PER_PAGE } from "constants/numbers";

/**
 * hook uses InfiniteSearch to load more the question list in infinite scroll manner.
 */
export const useInfiniteFormQuestions = ({
  bookingUuid,
  contentUuid,
  setSelectedQuestion,
} = {}) => {
  const {
    data: options,
    loading,
    setLoading,
    setError,
    setHasMore,
    pageNumber,
    setPageNumber,
    setData,
    lastElementRef,
  } = useInfiniteSearch();

  const fetchQuestions = (page) => {
    setLoading(true);
    setError(false);

    const queryParams = {
      content_uuid: contentUuid,
      booking_uuid: bookingUuid,
      page_size: DEFAULT_PER_PAGE,
      page: page,
    };

    dataProvider
      .custom_request(
        FORMSV2_API.GET_PAGINATED_FORM_QUESTIONS_LIST,
        apiMethods.GET,
        queryParams
      )
      .then((response) => {
        const { questions, total_pages } = response?.data || {};
        setHasMore(total_pages > page);
        if (page == 1) {
          setData(questions);
          if (setSelectedQuestion) setSelectedQuestion(questions[0]);
        } else setData((prevData) => [...prevData, ...questions]);
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  React.useEffect(() => fetchQuestions(pageNumber), [pageNumber]);

  React.useEffect(() => {
    /** In case we change the contentUuid we will need to set to default state. */
    setPageNumber(1);
    fetchQuestions(1);
  }, [contentUuid]);

  return {
    loading,
    options,
    lastElementRef,
  };
};
