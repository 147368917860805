import React from "react";
import FormQuestionSelector from "../FormQuestionSelector/FormQuestionSelector";
import ProgressTrackingFormNote from "./modules/ProgressTrackingFormNote/ProgressTrackingFormNote";
import { useDesktopFormResponsesController } from "./utils/useDesktopFormResponsesController";
import ExlyTableEmptyState from "common/Components/ExlyTable/ExlyTableEmptyState/ExlyTableEmptyState";
import ExlyTable from "features/Common/modules/ExlyTableV2/ExlyTable";
import FormsXAxisPagination from "./modules/FormsXAxisPagination/FormsXAxisPagination";
import { is_empty } from "utils/validations";
import { DEFAULT_PER_PAGE } from "constants/numbers";
import { getDeviceSpecificNoFormSelectedEmptyStateProps } from "../../utils/FormsV2.utils";
import { FORM_NOT_ATTEMPTED_YET_EMPTY_PROPS } from "../../constants/FormsV2.constants";

import styles from "./DesktopView.module.css";
import ExlySpinner from "ui/modules/ExlySpinner/ExlySpinner";
import ExlyEmptyState from "features/Common/modules/ExlyEmptyState/ExlyEmptyState";

const DesktopView = ({ emptyStateProps, bookingUuid, listingUuid }) => {
  const {
    selectedForm,
    handleFormSelection,
    isFormProgressTracking,
    isEmptyState,
    table,
    loading,
    formLoading,
    pageLoading,
    formLastElementRef,
    formOptions,
    total,
    page,
    setPage,
    formsTotal,
    formsPage,
    setFormsPage,
    formsPageSize,
    handleFormsPageSizeChange,
    currentCourseUsageCount,
    otherCoursesUsageCount,
  } = useDesktopFormResponsesController({ bookingUuid, listingUuid });

  if (isEmptyState) return <ExlyTableEmptyState {...emptyStateProps} />;
  if (pageLoading)
    return (
      <div className={styles.loader}>
        <ExlySpinner loaderWidth="64px" wrapperPadding="0 0" />
      </div>
    );

  return (
    <div className={styles.table}>
      <div className={styles.header}>
        <div className={styles.leftSection}>
          <div className={styles.formSelector}>
            <FormQuestionSelector
              formOptions={formOptions}
              lastElementRef={formLastElementRef}
              loading={formLoading}
              selectedForm={selectedForm}
              setSelectedForm={handleFormSelection}
              bookingUuid={bookingUuid}
            />
          </div>
          {isFormProgressTracking && !loading && table && (
            <ProgressTrackingFormNote
              currentCourseUsageCount={currentCourseUsageCount}
              otherCoursesUsageCount={otherCoursesUsageCount}
            />
          )}
        </div>
        {isFormProgressTracking && !loading && table && (
          <FormsXAxisPagination
            page={formsPage}
            setPage={setFormsPage}
            pageSize={formsPageSize}
            setPageSize={handleFormsPageSizeChange}
            total={formsTotal}
          />
        )}
      </div>
      {/** In Case form is not selected */}
      {!selectedForm && (
        <div className={styles.emptyFormResponses}>
          <ExlyEmptyState
            {...getDeviceSpecificNoFormSelectedEmptyStateProps(true)}
          />
        </div>
      )}

      {/** In case form is selected
       * 1. Form is selected but form was not attempted.
       * 2. Form is selected and responses are there to display.
       */}
      {table &&
        (is_empty(table.rows) ? (
          <div className={styles.emptyFormResponses}>
            <ExlyEmptyState {...FORM_NOT_ATTEMPTED_YET_EMPTY_PROPS} />
          </div>
        ) : (
          <ExlyTable
            columns={table.columns}
            rows={table.rows}
            loading={loading}
            stickyHeader={true}
            paginationProps={{
              total,
              page,
              setPage,
              perPage: DEFAULT_PER_PAGE,
            }}
          />
        ))}
    </div>
  );
};

export default DesktopView;
