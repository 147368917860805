import { recorded_content_media_types } from "constants/recordedContent";
import { QUESTIONNAIRE_TYPES } from "schedule-v2/components/recorded-content/recordedContent.data";

export const getQuestionnaireTypeBasedRecorderedContentMediaType = (type) => {
  switch (type) {
    case QUESTIONNAIRE_TYPES.EXAM:
      return recorded_content_media_types.exam;
    case QUESTIONNAIRE_TYPES.FORM:
      return recorded_content_media_types.form;
    default:
      return "";
  }
};

export const getDeviceSpecificNoFormSelectedEmptyStateProps = (isDesktop) => {
  return {
    imgSrc: "assets/vectors/recorded_content/no_form_selected.svg",
    title: "Nothing selected yet.",
    description: isDesktop
      ? "To view responses, start by selecting a form from the dropdown above. The data will appear here once your selections are made."
      : "To view responses, start by selecting a form from the dropdown above, then choose a specific question. The data will appear here once your selections are made.",
  };
};

export const checkIfIsFormProgressTracking = (type) =>
  type === recorded_content_media_types.progress_tracking_form;
