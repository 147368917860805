import React from "react";
import classes from "./FormsXAxisPagination.module.css";
import PreviousIcon from "@material-ui/icons/ArrowBackIosRounded";
import NextIcon from "@material-ui/icons/ArrowForwardIosRounded";
import { Input } from "@my-scoot/component-library-legacy";
import classnames from "classnames";
const FormsXAxisPagination = ({
  page,
  setPage,
  pageSize,
  setPageSize,
  total,
}) => {
  const getFirstFormNumber = (page - 1) * pageSize + 1;
  const getLastFormNumber = Math.min(page * pageSize, total);
  const isFirstPage = page === 1;
  const isLastPage = pageSize * page >= total;
  const previousButtonClass = page === isFirstPage && classes.disabled;
  const nextButtonClass = page === isLastPage && classes.disabled;

  const onPrevious = () => {
    if (!isFirstPage) setPage((p) => p - 1);
  };
  const onNext = () => {
    if (!isLastPage) setPage((p) => p + 1);
  };

  return (
    <div className={classes.container}>
      <div className={classes.pageSize}>
        Forms per page:
        <Input
          placeholder="size"
          size="small"
          value={pageSize}
          onChange={(e) => setPageSize(e.target.value)}
          fullWidth={false}
          className={classes.input}
        />
      </div>
      {pageSize && (
        <div className={classes.pageNumber}>
          <span className={classes.showingPageText}>
            Showing {getFirstFormNumber}-{getLastFormNumber} of {total} forms
          </span>
          <span className={classes.actionButtonFlex}>
            <span className={previousButtonClass} onClick={onPrevious}>
              <PreviousIcon
                className={classnames(
                  classes.actionButtonIcon,
                  page === isFirstPage ? classes.disabledButton : ""
                )}
              />
            </span>
            <span className={nextButtonClass} onClick={onNext}>
              <NextIcon
                className={classnames(
                  classes.actionButtonIcon,
                  page === isLastPage ? classes.disabledButton : ""
                )}
              />
            </span>
          </span>
        </div>
      )}
    </div>
  );
};

export default FormsXAxisPagination;
