import EmptyStateIcon from "assets/vectors/recorded-content/course_responses_empty_state.svg";

export const getResponseTypeBasedEmptyProps = (responseType) => {
  return {
    empty_list_icon: EmptyStateIcon,
    alt: "no responses yet",
    content_title: "No responses yet.",
    children: `All the responses for all the ${responseType} attempted by this student will get collected here.`,
  };
};
