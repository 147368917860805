import { SESSION_STORAGE_KEYS } from "../constants/SessionStorage.constant";

export const setSessionStorageItem = (key, value) => {
  if (Object.values(SESSION_STORAGE_KEYS).includes(key)) {
    sessionStorage.setItem(key, value);
  }
};

export const getSessionStorageItem = (key) => {
  if (Object.values(SESSION_STORAGE_KEYS).includes(key)) {
    return sessionStorage.getItem(key) ?? "";
  }
  return "";
};

export const removeSessionStorageItem = (key) => {
  if (Object.values(SESSION_STORAGE_KEYS).includes(key)) {
    sessionStorage.removeItem(key);
  }
};
