import React from "react";
import style from "./ProgressTrackingFormNote.module.css";
import { InteractiveTooltip } from "@my-scoot/component-library-legacy";
import { useToggleState } from "utils/hooks";
const ProgressTrackingFormNote = ({
  currentCourseUsageCount = 0,
  otherCoursesUsageCount = 0,
}) => {
  const [isPopoverOpen, openPopover, closePopover] = useToggleState(false);

  return (
    <div className={style.container}>
      <div className={style.body}>
        <div className={style.leftSection}>
          <img src="/assets/vectors/recorded_content/progress_tracking_form_blue.svg" />{" "}
          This is a Progress Tracking Form
        </div>
        <InteractiveTooltip
          totalSteps={0}
          primaryBtnText="Got it"
          title={
            <>
              <div>
                This is a Progress Tracking Form: It has been used{" "}
                {currentCourseUsageCount} times in this course and{" "}
                {otherCoursesUsageCount} times across other courses.
              </div>
              <div className="mt-2">
                Select question from the top, for which you wish to compare
                responses.
              </div>
            </>
          }
          arrow
          open={isPopoverOpen}
          onPrimaryBtnClick={closePopover}
        >
          <img
            src={"assets/vectors/recorded_content/question_icon_blue.svg"}
            onClick={openPopover}
            className={style.tooltipIcon}
          />
        </InteractiveTooltip>
      </div>
    </div>
  );
};

export default ProgressTrackingFormNote;
