import React from "react";
import WarningIcon from "@material-ui/icons/WarningRounded";
import classnames from "classnames";
import { ExlyModal } from "../ExlyModal/ExlyModal";
import styles from "./ExlyDeleteModal.module.css";
import { useDesktopMediaQuery } from "features/Common/hooks/useDesktopMediaQuery";

export const ExlyDeleteModal = ({
  title,
  description,
  onDelete,
  deleteBtnText = "Delete",
  showWarningIcon = false,
  loading,
  ...restProps
}) => {
  const isDesktop = useDesktopMediaQuery();

  return (
    <ExlyModal
      modal_props={{ modalPaperClassName: styles.modalPaperClassName }}
      title={
        <div
          className={classnames(
            isDesktop ? styles.desktopModalHeader : styles.mobileModalHeader
          )}
        >
          <WarningIcon className={styles.warningIcon} />
          <span>{title}</span>
        </div>
      }
      primaryBtnText={deleteBtnText}
      onPrimaryBtnClick={onDelete}
      primaryBtnProps={{
        className: styles.primaryBtnClassName,
        loading,
        disabled: loading,
      }}
      {...restProps}
    >
      <div
        className={classnames(
          isDesktop ? styles.desktopWarningWrapper : styles.mobileWarningWrapper
        )}
      >
        {showWarningIcon && <WarningIcon className={styles.warningIcon} />}

        <span>{description}</span>
      </div>
    </ExlyModal>
  );
};
