import React from "react";
import cx from "classnames";
import { Button } from "@my-scoot/component-library-legacy";

import style from "./CarouselHelperComponentsStyles.module.css";
import ExlyImage from "../ExlyImage/ExlyImage";
import { ARROW_IMG_SRC } from "./CarouselHelperComponents.constants";

export const CarouselPrevArrow = (props) => {
  const { onClick } = props;

  if (!onClick) return null;
  return (
    <ExlyImage
      onClick={onClick}
      className={cx(style.prevArrowBtn, props.customStyle)}
      src={ARROW_IMG_SRC}
    />
  );
};

export const CarouselNextArrow = (props) => {
  const { onClick, onClose, hideClose = false } = props;

  return onClick ? (
    <ExlyImage
      onClick={onClick}
      className={cx(style.nextArrowBtn, props.customStyle)}
      src={ARROW_IMG_SRC}
    />
  ) : (
    <Button
      color="subtlePrimary"
      onClick={onClose}
      buttonWrapperClassName={style.nextArrowBtn}
      className={cx(style.closeBtn, hideClose && style.hideClose)}
    >
      Close
    </Button>
  );
};
