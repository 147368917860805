import { QUESTION_TYPES } from "schedule-v2/components/recorded-content/recordedContent.data";

export const getQuestionTagData = (questionType) => {
  switch (questionType) {
    case QUESTION_TYPES.CALENDAR:
      return {
        label: "Date",
        color: "#ECCCFF",
      };
    case QUESTION_TYPES.MULTI_SELECT:
      return {
        label: "Multiple select",
        color: "#FDECAF",
      };
    case QUESTION_TYPES.SINGLE_SELECT:
      return { label: "Single select", color: "#FBD0E7" };
    case QUESTION_TYPES.FIVE_NUMBER_RATING:
      return {
        label: "Number rating",
        color: "#CAF3EF",
      };
    case QUESTION_TYPES.TEN_NUMBER_RATING:
      return {
        label: "Number rating",
        color: "#CAF3EF",
      };
    case QUESTION_TYPES.TEXT_QUESTION:
      return { label: "Short text", color: "#C5E1FF" };
    case QUESTION_TYPES.STAR_RATING:
      return { label: "Star rating", color: "#F9CF9C" };
    case QUESTION_TYPES.FILE:
      return { label: "File upload", color: "#CCE9BA" };
    default:
      return { label: "-", color: "" };
  }
};

export const FORM_COLUMNS_HEADERS = {
  FORM_QUESTION: "Form Questions",
  QUESTION_TYPE: "Question type",
  RESPONSES: "Responses",
};

export const FORM_RESPONSES_FIELDS = {
  UUID: "uuid",
  QUESTIONS: "questions",
  FORM_RESPONSES: "form_responses",
  QUESTION: "question",
  TITLE: "title",
  QUESTION_TYPE: "question_type",
  ANSWER: "answer",
  OPTIONS: "options",
  FORMS_LIST: "forms_list",
  RECORDED_CONTENT_UUID: "recorded_content_uuid",
  RECORDED_CONTENT_TYPE: "recorded_content_type",
  TOTAL: "total",
  FORMS_TOTAL: "forms_total",
};

export const DEFAULT_FORMS_PAGE_SIZE = 25;

export const CONTENT_UUID_SEARCH_PARAM_KEY = "content_uuid";
