import { format } from "date-fns";
import {
  MONTH_DAY_YEAR_FORMAT,
  MONTH_DAY_YEAR_TIME_FORMAT,
} from "./modules/DateTime/DateTime.constants";

/**
 * output :  "April 11, 2024, 12:17 PM"
 * Formats a date value into the format "Month Day, Year, Time".
 * @param {string} value - The date string to format.
 * @returns {string} The formatted date string.
 */
export const getDateInMonthDayYearTimeFormat = (value) => {
  return formatDateWithFns({ value, formatType: MONTH_DAY_YEAR_TIME_FORMAT });
};

/**
 * output :  "April 30, 2024"
 * Formats a date value into the format "Month Day, Year".
 * @param {string} value - The date string to format.
 * @returns {string} The formatted date string.
 */
export const getDateInMonthDayYearFormat = (value) => {
  return formatDateWithFns({ value, formatType: MONTH_DAY_YEAR_FORMAT });
};

/**
 * Formats a date value using date-fns.
 * @param {Object} options - Options for formatting the date.
 * @param {string} options.value - The date string to format.
 * @param {string} options.formatType - The format string to use for formatting.
 * @returns {string} The formatted date string.
 */
export const formatDateWithFns = ({
  value,
  formatType = MONTH_DAY_YEAR_FORMAT,
}) => {
  if (!value) return;
  return `${format(new Date(value), formatType)}`;
};
