import React from "react";
import { dataProvider } from "data";
import { useEffect, useState } from "react";
import {
  QUESTION_OPTION_KEYS,
  QUESTION_TYPES,
  QUESTIONNAIRE_FORM_KEYS,
} from "schedule-v2/components/recorded-content/recordedContent.data";
import { is_empty } from "utils/validations";
import {
  DEFAULT_FORMS_PAGE_SIZE,
  FORM_COLUMNS_HEADERS,
  FORM_RESPONSES_FIELDS,
  CONTENT_UUID_SEARCH_PARAM_KEY,
  getQuestionTagData,
} from "./DesktopView.utils";
import styles from "../DesktopView.module.css";
import { FormQuestion } from "schedule-v2/components/recorded-content/ExamCreation/pages/Questionnaire/HelperComponents/components/HelperComponents";
import { DateConvert } from "utils/dateTimeUtils";
import { useInfiniteFormOptions } from "features/RecordedContent/modules/FormsV2/utils/useInfiniteFormOptions";
import useDebounce from "hooks/useDebounce";
import { apiMethods } from "data/api.constants";
import { DEFAULT_PER_PAGE } from "constants/numbers";
import classnames from "classnames";
import { FORMSV2_API } from "features/RecordedContent/modules/FormsV2/utils/FormsV2.utils";
import { checkIfIsFormProgressTracking } from "../../../utils/FormsV2.utils";
import { useHistory, useLocation } from "react-router-dom";
import { logError } from "utils/error";

export const useDesktopFormResponsesController = (props) => {
  const history = useHistory();
  const location = useLocation();

  const [selectedForm, setSelectedForm] = useState();
  const {
    options: formOptions,
    loading: formLoading,
    lastElementRef: formLastElementRef,
  } = useInfiniteFormOptions(props);

  const [pageLoading, setPageLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [formsPage, setFormsPage] = useState(1);

  const [table, setTable] = useState();
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState();
  const [formsTotal, setFormsTotal] = useState();

  const {
    value: formsPageSize,
    setValue: setFormsPageSize,
    debouncedValue: debounceFormsPageSize,
  } = useDebounce({
    inititalValue: DEFAULT_FORMS_PAGE_SIZE,
    delay: 500,
  });

  const isEmptyState = is_empty(formOptions) && !formLoading && !pageLoading;

  const isFormProgressTracking = checkIfIsFormProgressTracking(
    selectedForm?.[FORM_RESPONSES_FIELDS.RECORDED_CONTENT_TYPE]
  );

  const currentCourseUsageCount =
    selectedForm?.[QUESTIONNAIRE_FORM_KEYS.currentCourseUsageCount];
  const otherCoursesUsageCount =
    selectedForm?.[QUESTIONNAIRE_FORM_KEYS.otherCoursesUsageCount];

  const handleFormSelection = (form) => {
    history.replace(
      `?${CONTENT_UUID_SEARCH_PARAM_KEY}=${
        form[FORM_RESPONSES_FIELDS.RECORDED_CONTENT_UUID]
      }`
    );
    setSelectedForm(form);
    setFormsPage(1);
    setFormsPageSize(DEFAULT_FORMS_PAGE_SIZE);
    setPage(1);
  };

  const handleFormsPageSizeChange = (pageSize) => {
    setFormsPageSize(pageSize);
    setFormsPage(1);
    setPage(1);
  };

  const updateTable = (data) => {
    setTable({
      rows: getFormRows(data),
      columns: isFormProgressTracking
        ? getProgressTrackingFormColumns(data)
        : singleFormColumns,
    });
  };

  const getProgressTrackingFormColumns = (data) => {
    let ptFormColumns = [
      {
        field: FORM_RESPONSES_FIELDS.QUESTION,
        headerName: FORM_COLUMNS_HEADERS.FORM_QUESTION,
        fixed: "left",
        isPrimary: true,
        renderCell: (row) => (
          <div className={styles.questions}>
            {row[FORM_RESPONSES_FIELDS.QUESTION]}
          </div>
        ),
        renderHeader: (column) => (
          <div className={styles.responseColumnHeader}>
            <div>{column.headerName}</div>
          </div>
        ),
      },
    ];

    ((data || {})[FORM_RESPONSES_FIELDS.FORMS_LIST] || []).forEach((form) => {
      const formResponses = data[FORM_RESPONSES_FIELDS.QUESTIONS].find(
        (_form) => _form[FORM_RESPONSES_FIELDS.UUID]
      );
      const isFormAttempted = Object.prototype.hasOwnProperty.call(
        formResponses[FORM_RESPONSES_FIELDS.FORM_RESPONSES],
        form[FORM_RESPONSES_FIELDS.UUID]
      );
      ptFormColumns.push({
        field: form[FORM_RESPONSES_FIELDS.UUID],
        headerName: form[FORM_RESPONSES_FIELDS.TITLE],
        renderCell: (row) =>
          isFormAttempted
            ? renderResponses(row[form[FORM_RESPONSES_FIELDS.UUID]])
            : "-",
        renderHeader: (column) => {
          return (
            <div className={styles.responseColumnHeader}>
              <div>{column.headerName}</div>
              {!isFormAttempted && (
                <div className={styles.responseColumnHeaderDesc}>
                  (not attempted yet)
                </div>
              )}
            </div>
          );
        },
      });
    });

    return ptFormColumns;
  };

  const getFormRows = (data) => {
    let rows = [];
    if (data) {
      if (!isFormProgressTracking) return data[FORM_RESPONSES_FIELDS.QUESTIONS];
      (data[FORM_RESPONSES_FIELDS.QUESTIONS] || []).forEach((question) => {
        let row = question;
        const responses = question[FORM_RESPONSES_FIELDS.FORM_RESPONSES];
        Object.keys(responses).forEach((k) => {
          row[k] = responses[k];
        });
        rows.push(row);
      });
    }

    return rows;
  };

  const renderResponses = (question) => {
    switch (question[FORM_RESPONSES_FIELDS.QUESTION_TYPE]) {
      case QUESTION_TYPES.STAR_RATING:
      case QUESTION_TYPES.FIVE_NUMBER_RATING:
      case QUESTION_TYPES.TEN_NUMBER_RATING:
      case QUESTION_TYPES.FILE:
        return (
          <div className={classnames(styles.formQuestions, "mt-2")}>
            <FormQuestion isResponse isDisabled question={question} />
          </div>
        );
      case QUESTION_TYPES.SINGLE_SELECT:
      case QUESTION_TYPES.MULTI_SELECT:
        return (
          <div className="my-2">
            {getCommaSeperatedSelectedOptions(
              question[FORM_RESPONSES_FIELDS.OPTIONS]
            )}
          </div>
        );
      case QUESTION_TYPES.CALENDAR:
        return (
          question[FORM_RESPONSES_FIELDS.ANSWER] &&
          DateConvert(question[FORM_RESPONSES_FIELDS.ANSWER])
        );
      default:
        return question[FORM_RESPONSES_FIELDS.ANSWER];
    }
  };

  const getCommaSeperatedSelectedOptions = (options) => {
    if (options) {
      let selectedOptions = [];
      options.map((option) => {
        if (option[QUESTION_OPTION_KEYS.isSelected])
          selectedOptions.push(option[QUESTION_OPTION_KEYS.option]);
      });

      return selectedOptions.join(", ");
    }
    return "";
  };

  const validateFormsPageSize = (_pageSize) => {
    if (_pageSize <= 0 || _pageSize > formsTotal || isNaN(_pageSize)) {
      setFormsPageSize(formsTotal);
      return true;
    }
    return false;
  };

  const singleFormRenderHeader = (column) => (
    <div className={styles.columnHeader}>
      <div>{column.headerName}</div>
    </div>
  );

  const singleFormColumns = [
    {
      field: FORM_RESPONSES_FIELDS.QUESTION,
      headerName: FORM_COLUMNS_HEADERS.FORM_QUESTION,
      fixed: "left",
      isPrimary: true,
      renderCell: (row) => (
        <div className={styles.questions}>
          {row[FORM_RESPONSES_FIELDS.QUESTION]}
        </div>
      ),
      renderHeader: singleFormRenderHeader,
    },
    {
      field: "",
      headerName: FORM_COLUMNS_HEADERS.QUESTION_TYPE,
      width: "20%",
      renderCell: (row) => {
        const { color, label } = getQuestionTagData(
          row[FORM_RESPONSES_FIELDS.QUESTION_TYPE]
        );
        return (
          <div
            style={{
              backgroundColor: color,
            }}
            className={styles.tag}
          >
            {label}
          </div>
        );
      },
      renderHeader: singleFormRenderHeader,
    },
    {
      field: "",
      headerName: FORM_COLUMNS_HEADERS.RESPONSES,
      renderCell: (row) => {
        return <div className={styles.responses}>{renderResponses(row)}</div>;
      },
      renderHeader: singleFormRenderHeader,
    },
  ];

  useEffect(() => {
    setPageLoading(true);
    const contentUuid = new URLSearchParams(location.search).get(
      CONTENT_UUID_SEARCH_PARAM_KEY
    );
    dataProvider
      .custom_request(FORMSV2_API.GET_PAGINATED_FORMS_LIST, apiMethods.GET, {
        booking_uuid: props.bookingUuid,
        listing_uuid: props.listingUuid,
        content_uuid: contentUuid,
      })
      .then(({ data: { forms } }) => setSelectedForm(forms[0]))
      .catch((error) => {
        logError({
          error,
          occuredAt:
            "src/features/CourseAnalytics/modules/ContentResponses/modules/FormsV2/modules/DesktopForms/utils/useDesktopFormResponsesController.js",
          when: "Fetching data for preselected form",
        });
      })
      .finally(() => setPageLoading(false));
  }, []);

  useEffect(() => {
    if (selectedForm) {
      setLoading(true);

      const defaultQueryParams = {
        page: page,
        page_size: DEFAULT_PER_PAGE,
        booking_uuid: props.bookingUuid,
        content_uuid: selectedForm[FORM_RESPONSES_FIELDS.RECORDED_CONTENT_UUID],
      };
      const queryParams = isFormProgressTracking
        ? {
            ...defaultQueryParams,
            forms_page: formsPage,
            forms_page_size: debounceFormsPageSize || DEFAULT_FORMS_PAGE_SIZE,
          }
        : defaultQueryParams;

      dataProvider
        .custom_request(
          isFormProgressTracking
            ? FORMSV2_API.GET_PAGINATED_PROGRESS_TRACKING_FORM_RESPONSES_DESKTOP
            : FORMSV2_API.GET_PAGINATED_FORM_RESPONSES,
          apiMethods.GET,
          queryParams
        )
        .then(({ data }) => {
          updateTable(data);
          setTotal(data[FORM_RESPONSES_FIELDS.TOTAL]);
          if (isFormProgressTracking) {
            setFormsTotal(data[FORM_RESPONSES_FIELDS.FORMS_TOTAL]);
          }
        })
        .catch((error) => {
          validateFormsPageSize(debounceFormsPageSize);
          logError({
            error,
            occuredAt:
              "src/features/CourseAnalytics/modules/ContentResponses/modules/FormsV2/modules/DesktopForms/utils/useDesktopFormResponsesController.js",
            when: "Fetching responses for forms",
          });
        })
        .finally(() => setLoading(false));
    }
  }, [selectedForm, page, formsPage, debounceFormsPageSize]);

  return {
    pageLoading,
    formLoading,
    formLastElementRef,
    formOptions,
    selectedForm,
    handleFormSelection,
    isFormProgressTracking,
    isEmptyState,
    page,
    setPage,
    total,
    table,
    formsPage,
    setFormsPage,
    formsPageSize,
    handleFormsPageSizeChange,
    formsTotal,
    loading,
    currentCourseUsageCount,
    otherCoursesUsageCount,
  };
};
