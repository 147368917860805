import { AUTH_STORAGE_CONSTANTS } from "authConstants";
import local_storage_keys from "constants/LocalStorage.constants";

export const LOCAL_STORAGE_KEYS = {
  NEXT_STEPS: "next_steps",
  BD_LOGIN: AUTH_STORAGE_CONSTANTS.BD_LOGIN,
  IS_STAFF: AUTH_STORAGE_CONSTANTS.IS_STAFF,
  BD_EMAIL: AUTH_STORAGE_CONSTANTS.BD_EMAIL,
  ACCESS_TOKEN: "access_token",
  REFRESH_TOKEN: "refresh_token",
  TOKEN_UUID: "token_uuid",
  ORG_UUID: "org_uuid",
  IS_FEATURE_ONBOARDED: local_storage_keys.IS_FEATURE_ONBOARDED,
};
