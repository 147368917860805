export const CURRENCY_SYMBOL_MAP = {
  INR: "₹",
  USD: "$",
  EUR: "€",
  GBP: "£",
  CAD: "CA$",
  AED: "د.إ",
  AUD: "AU$",
  IDR: "Rp",
  SAR: "SR",
  ARS: "AR$",
  TTD: "TT$",
  NGN: "₦",
  PKR: "PKRs",
  MUR: "MURs",
  MMK: "K",
  EGP: "EGP",
  HUF: "Ft",
  ZAR: "R",
  PHP: "₱",
  THB: "฿",
  SGD: "S$",
  KHR: "KHR",
  LAK: "₭",
  BDT: "Tk",
  MYR: "RM",
};

export const RUPEE_SYMBOL = "₹";
